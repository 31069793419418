import React from "react"
import { graphql } from "gatsby"
import { FaChevronLeft } from "react-icons/fa"
import Map from "../components/Map/Map"
import HuntWalkHeader from "../components/HuntWalkHeader/HuntWalkHeader"
import Layout from "../components/layout/Layout/Layout"

// Todo Add listing data for content types other than Pages
export const query = graphql`
  query($journeyId: String!, $stepId: String) {
    datoCmsJourney(id: { eq: $journeyId }) {
      title
      route
      slug
      steps {
        title
        location {
          longitude
          latitude
        }
      }
      mapFallbackImageDesktop: mapFallbackImage {
        fluid(maxWidth: 730, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      mapFallbackImageMobile: mapFallbackImage {
        fluid(maxWidth: 660, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    datoCmsJourneyStep(id: {eq: $stepId}) {
      location {
        latitude
        longitude
      }
      title
    }
  }
`

const JourneyMap = props => {
  const markers = []
  const journey = props.data.datoCmsJourney

  journey.steps.map((step) => {
    return (
      markers.push({
        lat: step.location.latitude,
        lng: step.location.longitude,
        description: step.title,
      })
    )
  })

  const lat = props.data.datoCmsJourneyStep ? props.data.datoCmsJourneyStep.location.latitude : markers[0].lat
  const lng = props.data.datoCmsJourneyStep ? props.data.datoCmsJourneyStep.location.longitude : markers[0].lng

  let previousPage = journey.slug
  if (props.data.datoCmsJourneyStep) {
    previousPage += "/step/" + (props.pageContext.stepNumber + 1)
  } else {
    previousPage = props.location.state ? props.location.state.prevPath : '/'
  }

  return (
    <Layout metaTitle={journey.route + " map"} removeNav={true}>
      <HuntWalkHeader
        previousPage={previousPage}
        backButton={<FaChevronLeft/>}
      />
      <Map
        polylineEncoded = {journey.route}
        markers = {markers}
        showLabelsOnLoad={true}
        showLabelAtIndex={props.pageContext.stepNumber}
        lat={lat}
        lng={lng}
        fullScreen={true}
        fallbackImage={[journey.mapFallbackImageDesktop.fluid, journey.mapFallbackImageMobile.fluid]}
      />
    </Layout>
  )
}

export default JourneyMap
